import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

// const BFBZ_CC_URL = "http://bfbz.cc/api/"
const REMOTE_IP_URL = 'http://172.31.7.100:7030/api/'
const LOCAL_IP_URL = 'http://localhost:8080/api/' // eslint-disable-line no-unused-vars


export default new Vuex.Store({
  state: {
    baseUrl:REMOTE_IP_URL,
  },
  getters: {

  },
  mutations: {

  },
  actions: {

  },
  modules: {
    
  }
})
