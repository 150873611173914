<template>
  <div id="app">
    <top_navigator></top_navigator>
    <router-view/>
  </div>
</template>

<script>
  import  top_navigator from '@/components/TopNavigator.vue'
  export default {
    components:{
      top_navigator
    },
    data(){
      return {
        
      }
    },
    methods:{
      
    }
  }
</script>

<style lang="scss" >
  body{
    margin: 0 ;
  }
  #app{
    position: relative;
  }
</style>
