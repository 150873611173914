import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

import axios from 'axios'

import MuseUI from "muse-ui"
import 'muse-ui/dist/muse-ui.css'

Vue.use(MuseUI)

Vue.config.productionTip = false

Vue.use(ElementUI)
Vue.prototype.axios = axios
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
