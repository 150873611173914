<template>
  <div class="header-content">
    <div class="page-frame">
      <div class="image">
        <img src="../assets/my_photo/qianmanwu2.jpg" class="logo"/> 
      </div>
      <ul class="navigate-list flex align-center">
        <a class="navigate-item" :class="index == 0 ? 'nactive': ''" @click="navigateTo(0)" >零售商城</a>
        <!-- <a class="navigate-item" :class="index == 1 ? 'nactive': ''" @click="navigateTo(1)" >广告营销</a> -->
<!--		<a class="navigate-item" :class="index == 2 ? 'nactive': ''" @click="navigateTo(2)">快应用软件</a>-->
        <a class="navigate-item" :class="index == 2 ? 'nactive': ''" @click="navigateTo(2)">树峰软件</a>
        <!-- <a class="navigate-item" >
          <div class="flex align-center"> 
            <span>更多产品</span> <img src="../assets/my_photo/arrow_down.svg" />
          </div>
        </a>
        <a class="navigate-item" href="/about">关于我们</a> -->
      </ul>
    </div>
  </div>
</template>

<script>
export default {
	data(){
		return {
			index: 0,
			map: ['','advertise','app']
		}
	},
	methods:{
		navigateTo(index){
			this.index = index
			this.$router.push('/'+this.map[index]);
		}
	},
    watch:{
        '$route': function(newValue){
            this.index = this.map.indexOf(newValue.path.slice(1));
        }
    }
}
</script>

<style lang="scss" scoped>
.header-content {
    padding: 15px 0 15px 0;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    color: rgba(255, 255, 255, 0.7);
    background-color: rgba(255, 255, 255, 0);
    transition: all 0.2s ease-in-out;
    z-index: 15;
    min-width: 1150px;
}
.active {
    background-color: #ffffff;
    // box-shadow: rgb(85 141 231 / 10%) 0 8px 10px 0;
    color: #7381A3;
}
.header-content .page-frame {
    max-width: 1156px;
    margin: 0 auto;
    position: relative;

    display: flex;
    align-items: center;
    .image{
      transform: translate(0px, 0px);
      .logo{
        height: 46px;
        margin-right: 88px;

        vertical-align: middle;
        border-style: none;
      }
    }
    .navigate-list{
        .navigate-item {
			position: relative;
			margin-right: 60px;
			font-weight: bold;
			font-size: 18px;
			display: block;
			padding: 10px 0 7px;
			// color: rgba(255, 255, 255, 0.7);
			text-decoration: none;
			cursor: pointer;
			color: #aaaaaa;

			span{
				padding-right: 10px;
			}
			img{
				width: 14px;
			}
		}
		.nactive {
			color: #ffffff;
		}
		.nactive::after {
			content: "";
			display: block;
			position: absolute;
			bottom: -4px;
			background-color: #ffffff;
			width: 100%;
			left: 0;
			height: 3px;
			border-radius: 1.5px;
		}
	}
}
.flex{
  display: flex;
  .align-center{
    align-items: center;
  }
}

</style>
